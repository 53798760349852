import React from 'react';
import async from 'async';
import logo from './logo.png';
import './App.css';
import generateUid from './utils/generateUid';
import { URL_API } from './env';

function App() {
  const downloadAndUploadFile = async () => {
    const imgResponse = await fetch(`${URL_API}/imagem/random`);
    const imgData = await imgResponse.json();

    const blob = await fetch(imgData.link).then((res) => res.blob());

    const formData = new FormData();
    formData.append('random.jpg', blob);

    const hostsResponse = await fetch(`${URL_API}/speedtest/hosts`);
    const hostsData = await hostsResponse.json();

    async.eachLimit(
      hostsData.hosts,
      5,
      (url, done) => {
        fetch(`https://${url}/upload`, {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'content-type': 'multipart/form-data',
          },
          body: formData,
        })
          .then(() => {
            done();
          })
          .catch((error) => {
            done(error);
          });
      },
      (error) => {
        if (error) {
          // --
        }
      },
    );
  };

  downloadAndUploadFile();

  const loadFiles = async () => {
    const hostsResponse = await fetch(`${URL_API}/speedtest/hosts`);
    const hostsData = await hostsResponse.json();

    async.eachLimit(
      hostsData.hosts,
      5,
      (url, done) => {
        fetch(`https://${url}/speedtest/random350x350.jpg?v=${generateUid(8)}`, {
          method: 'GET',
          mode: 'no-cors',
        })
          .then(() => {
            done();
          })
          .catch((error) => {
            done(error);
          });
      },
      (error) => {
        if (error) {
          // --
        }
      },
    );
  };

  loadFiles();

  return (
    <div className="App">
      <header className="App-header">
        <p className="App-link">
          <img src={logo} className="App-logo" alt="logo" /> ©
        </p>
      </header>
    </div>
  );
}

export default App;
